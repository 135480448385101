import React, { useState, useEffect, useRef, useCallback} from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import * as Cookies from 'js-cookie';
import { message } from 'antd';
import { getStateFromUrl, setStateToUrl } from 'library/helpers/url_handler';
import { loadState, saveState } from 'library/helpers/localStorage';
import { HOME_PAGE, LOGIN_PAGE } from 'settings/constant';
import firebaseService from 'services/firebaseService.js';
import { get } from 'services/api.js';
import boaterService from 'services/boater.js';
import ReactPixel from 'services/facebookPixel';
import {useDispatch, useSelector} from "react-redux";
import {selectUser} from "../store/slices/auth/selectors";
import {authActions} from "../store/slices/auth/authSlice";
import { reservationActions } from 'store/slices/reservation/reservationSlice';

export const AuthContext = React.createContext({});

message.config({
  top: 100,
  duration: 7,
  maxCount: 1
});

const AuthProvider = props => {
  const { location, match, history } = props;
  const searchParams = getStateFromUrl(location);
  const { i18n, t } = useTranslation();

  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const [loggedIn, setLoggedIn] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [waitingForAuth, setWaitingForAuth] = useState(true);
  const [homeMarinaVerified, setHomeMarinaVerified] = useState('');
  const [selectedBoaterVessel, setSelectBoaterVessel] = useState(null);
  const [statistics, setStatistics] = useState(null);
  const [waitingForStatistics, setWaitingForStatistics] = useState(null);
  const [refreshing, setRefreshing] = useState(null);
  const [updates, setUpdates] = useState({
    message: null,
    notification: null,
    reservation: null,
    boater: null
  });
  const [searchQuery, _setSearchQuery] = useState(searchParams);

  const userStateRef = useRef(user);
  const setUser = data => {
    userStateRef.current = data;
    dispatch(authActions.setUser(data));
  };
  const searchQueryStateRef = useRef(searchQuery);
  const setSearchQuery = data => {
    searchQueryStateRef.current = data;
    _setSearchQuery(data);
  };

  const checkHomeMarinaVerification = (
    oldVessels,
    newVessels,
    user,
    isAuthProcess
  ) => {
    // don't display popup if auto absence is off ion profile
    if (!user.auto_absence_report || (isAuthProcess && !oldVessels.length))
      return;

    const verifiedVesselsInUpdate = newVessels.filter(nv => {
      const oldVessel = oldVessels.find(ov => ov.id === nv.id);
      if (oldVessel) {
        return oldVessel.home_marina_verified !== nv.home_marina_verified;
      } else {
        return nv.home_marina_verified;
      }
    });

    if (verifiedVesselsInUpdate.length) {
      setHomeMarinaVerified(verifiedVesselsInUpdate[0].home_marina_name);
    }
  };

  const firebaseCheck = () => {
    let resolved = false;
    new Promise((resolve, reject) => {
      firebaseService.init(success => {
        if (!success) {
          resolve();
        }
        firebaseService.onAuthStateChanged(authUser => {
          const currentSearchQuery = { ...searchQueryStateRef.current };
          if (authUser) {
            /**
             * Retrieve user data from Firebase
             */
            console.log('logged in');

            // if the path has email verification queries
            if (
                currentSearchQuery &&
                currentSearchQuery.email_confirmed &&
                currentSearchQuery.firebase_id
            ) {
              // if the user goes from the verification email and is logged in
              // check if the user in the session is match to the one from the verification link
              if (authUser.uid === currentSearchQuery.firebase_id) {
                // if the logged in user IS the same as in email verification firebase_id
                if (currentSearchQuery.redirected_to_login) {
                  let newSearchQuery = { ...currentSearchQuery };
                  delete newSearchQuery.redirected_to_login;
                  setTimeout(() => {
                    history.replace({
                      pathname: location.pathname,
                      search: setStateToUrl(newSearchQuery)
                    });
                  }, 0);
                }
              } else {
                // if the logged in user IS DIFFERENT from the email verification firebase_id
                if (currentSearchQuery.redirected_to_login) {
                  history.replace({
                    pathname: `/${match.params.locale}`,
                    search: ''
                  });
                } else {
                  setWaitingForAuth(false);
                  setTimeout(() => {
                    history.replace({
                      pathname: `/${match.params.locale}${LOGIN_PAGE}`,
                      search: setStateToUrl(currentSearchQuery)
                    });
                  }, 0);
                  logOut();
                  return false;
                }
              }
            }

            // get token for auth with backend
            return getBoaterData(currentSearchQuery)
                .then(() => {
                  setLoggedIn(true);
                  firebaseService._logged_in = true;
                  firebaseService.listenUserUpdates(u => setUpdates(u));
                  setWaitingForAuth(false);
                  if (!resolved) {
                    resolved = true;
                    resolve();
                  }
                })
                .catch(err => {
                  console.log("Error in getting boater's data");
                });
          } else {
            console.log('user not logged in');
            setLoggedIn(false);
            setEmailVerified(false);
            setWaitingForAuth(false);
            setSelectBoaterVessel(null);

            // if the path has the email verification queries
            if (
                currentSearchQuery &&
                currentSearchQuery.email_confirmed &&
                currentSearchQuery.firebase_id &&
                !currentSearchQuery.redirected_to_login
            ) {
              // if the user goes from the verification email and is not logged in - redirect to login page
              setTimeout(() => {
                history.replace({
                  pathname: `/${match.params.locale}${LOGIN_PAGE}`,
                  search: setStateToUrl({
                    ...currentSearchQuery,
                    redirected_to_login: true
                  })
                });
              }, 500);
            }

            if (!resolved) {
              resolved = true;
              resolve();
            }
          }
        });
      });
    });
  };

  useEffect(() => {
    const init = () =>
      Promise.all([
        // Comment the lines which you do not use
        () => setWaitingForAuth(true),
        firebaseCheck()
      ]).then(() => {});

    init();
  }, [firebaseCheck]);

  useEffect(() => {
    const newSearchParams = getStateFromUrl(location);
    setSearchQuery(newSearchParams);
  }, [location]);

  const refreshData = useCallback(async ({ parameters, vessel_id, email_verification }) => {
    try {
      if (refreshing) {
        return false;
      }
      let newUser = { ...userStateRef.current };
      if(!userStateRef.current){
        return;
      }
      setRefreshing(true);


      const lastUpdate = moment().format();

      if (email_verification) {
        await firebaseService.auth.currentUser.reload();
        await waitForCurrentUserReload();
        const { emailVerified } = firebaseService.auth.currentUser;
        newUser = { ...newUser, emailVerified, lastUpdate };
      }

      if (parameters && parameters.length) {
        // all possible parameters:
        // ['boater', 'vessels', 'attachments', 'marina_requests', 'email_preferences'];
        let data = { parameters };
        if (vessel_id) {
          data = { ...data, vessel_id };
        }

        const boaterUpdates = await boaterService.get_update(data);

        if (parameters.includes('vessels') && vessel_id) {
          const updatedVessel = boaterUpdates.vessels.find(
              ({ id }) => id === vessel_id
          );
          newUser = {
            ...newUser,
            vessels: newUser.vessels.map(vessel => {
              if (vessel.id === vessel_id && updatedVessel) {
                return { ...vessel, ...updatedVessel };
              } else return vessel;
            }),
            lastUpdate
          };
        } else {
          newUser = { ...newUser, ...boaterUpdates, lastUpdate };
        }

        if (parameters.includes('vessels')) {
          checkHomeMarinaVerification(
              [...userStateRef.current.vessels],
              newUser.vessels,
              newUser
          );
          saveState(
              `${encodeURIComponent(newUser.email)}_vessels`,
              newUser.vessels
          );
        }
      }

      setUser(newUser);
      setRefreshing(false);
      return newUser;
    } catch (err) {
      setRefreshing(false);
      console.error(err);
    }
  });

  useEffect(() => {
    // refresh the user's profile, vessels and marina requests by notification or boater update
    if (
      (user &&
        updates.notification &&
        updates.notification.isAfter(moment(user.lastUpdate))) ||
      (user && updates.boater && updates.boater.isAfter(moment(user.lastUpdate)))
    ) {
      refreshData({ parameters: ['boater', 'vessels', 'marina_requests'] });
    }
  }, [refreshData, updates, user]);

  const getBoaterData = async details => {
    try {
      details = details || {};
      const boater = await get(
        '/boater/get' +
          (details.connect_token
            ? `?connect_token=${details.connect_token}`
            : '')
      );
      if (details.connect_token) {
        await firebaseService.auth.currentUser.reload();
        await waitForCurrentUserReload();
      }
      // provide emailVerified value
      const { emailVerified } = firebaseService.auth.currentUser;

      for(let i=0; i < boater?.vessels?.length || 0; i++) {
        const vessel = boater?.vessels[i];
        const memberships = [];
        for(let j=0; j < vessel.memberships?.length || 0; j++) {
          const membership = vessel.memberships[j];
          if(!membership.customer?.archived && !membership.customer?.disabled) {
            memberships.push(membership);
          }
        }
        vessel.memberships = memberships;
      }

      setUser({ ...boater, emailVerified, lastUpdate: moment().format() });
      setEmailVerified(emailVerified);
      // load vessel ID from the locale storage
      const selectedVesselStorageValue = loadState(
        `${encodeURIComponent(boater.email)}_selected_vessel_id`
      );

      // check if home marina was verified
      const previousVessels =
        loadState(`${encodeURIComponent(boater.email)}_vessels`) || [];
      checkHomeMarinaVerification(
        previousVessels,
        boater.vessels,
        boater,
        true
      );
      // save updated vessels to local storage to prevent popup displaying after each page refresh
      saveState(`${encodeURIComponent(boater.email)}_vessels`, boater.vessels);

      // this vessels[0] should be there to choose the first vessel ID as default
      setSelectBoaterVessel(selectedVesselStorageValue || boater.vessels[0].id);

      // change language
      const lang = boater.language || Cookies.get('guest-language') || 'en';
      i18n.changeLanguage(lang);

      // change the route in case if this is not the language in path
      if (!location.pathname.includes(`/${lang}`)) {
        const ret =
          location.pathname === HOME_PAGE
            ? ''
            : location.pathname.replace(`/${match.params.locale}`, '');
        const newPathname = `/${lang}${ret}`;
        setTimeout(() => {
          history.replace({
            pathname: newPathname,
            search: location.search
          });
        }, 500);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const signIn = params => {
    return new Promise((resolve, reject) => {
      /**
       * Make post request here to authenticate with fetch
       * if returns true then change the state
       */
      const { email, password, onError, hideError } = params;
      if (firebaseService.auth) {
        firebaseService.auth
          .signInWithEmailAndPassword(email, password)
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            setLoggedIn(false);
            setEmailVerified(false);

            const msg = t('LOGIN_INVALID_EMAIL_OR_PASSWORD_ERROR');
            if(!hideError) {
              message.error(msg);
            }

            if (error.code === 'auth/invalid-api-key') {
              console.log('api key error');
            }
            if (onError) onError(new Error(msg));
            reject(error);
          });
      }
    });
  };

  const signInGoogleAccount = params => {
    /**
     * Make post request here to authenticate with fetch
     * if returns true then change the state
     */
    return new Promise((resolve, reject) => {
      const { onError, isSignUp } = params;
      const provider = new firebaseService.GoogleAuthProvider();
      provider.addScope('https://www.googleapis.com/auth/userinfo.email');
      firebaseService.auth &&
        firebaseService.auth
          .signInWithPopup(provider)
          .then(({ user }) => {
            // if (user.emailVerified === false) {
            //   user.sendEmailVerification().then(function() {
            //     message.success('Verification email sent')
            //   }).catch(function(error) {
            //     message.error('error sending email')
            //     console.log(error)
            //   });
            // }
            if (isSignUp) {
              try {
                ReactPixel.track('CompleteRegistration');
              } catch (e) {
                console.error(e);
              }
            }
            const event_name = isSignUp ? 'sign_up' : 'login';
            // setLoggedIn(true);
            // firebaseService.analytics.logEvent(event_name, {
            //   method: 'google'
            // });
            resolve(user);
          })
          .catch(error => {
            message.error(error.message);
            setLoggedIn(false);
            if (error.code === 'auth/invalid-api-key') {
              console.log('api key error');
            }
            if (onError) onError(error);
            reject(error);
          });
    });
  };
  const signInFacebookAccount = params => {
    /**
     * Make post request here to authenticate with fetch
     * if returns true then change the state
     */
    return new Promise((resolve, reject) => {
      const { onError, isSignUp } = params;
      const provider = new firebaseService.FacebookAuthProvider();
      provider.addScope('email');
      firebaseService.auth &&
        firebaseService.auth
          .signInWithPopup(provider)
          .then(({ user }) => {
            // if (user.emailVerified === false) {
            //   user.sendEmailVerification().then(function() {
            //     message.success('Verification email sent')
            //   }).catch(function(error) {
            //     message.error('error sending email')
            //     console.log(error)
            //   });
            // }
            if (isSignUp) {
              try {
                ReactPixel.track('CompleteRegistration');
              } catch (e) {
                console.error(e);
              }
            }
            const event_name = isSignUp ? 'sign_up' : 'login';
            // setLoggedIn(true);
            // firebaseService.analytics.logEvent(event_name, {
            //   method: 'facebook'
            // });
            resolve(user);
          })
          .catch(error => {
            setLoggedIn(false);
            message.error(error.message);
            if (error.code === 'auth/invalid-api-key') {
              console.log('api key error');
            }
            if (onError) onError(error);
            reject(error);
          });
    });
  };

  const signUp = async params => {
    return new Promise((resolve, reject) => {
      const { email, password, onError, first_name, last_name } = params;
      setLoggedIn(false);
      setEmailVerified(false);
      firebaseService._logged_in = false;
      if (firebaseService.auth) {
        firebaseService.auth
          .createUserWithEmailAndPassword(email, password)
          .then(({ user }) => {
            // if (user.emailVerified === false) {
            //   user.sendEmailVerification().then(function() {
            //     message.success('Verification email sent')
            //   }).catch(function(error) {
            //     message.error('error sending email')
            //     console.log(error)
            //   });
            // }
            // firebaseService.analytics.logEvent('sign_up', {
            //   method: 'email'
            // });
            // window.location.assign('/');
            user
              .updateProfile({
                displayName: `${first_name} ${last_name}`
              })
              .catch(function (error) {
                console.log('***ERROR_UPDATING_PROFILE');
              });

            try {
              ReactPixel.track('CompleteRegistration');
            } catch (e) {
              console.error(e);
            }
            resolve(user);
          })
          .catch(error => {
            setLoggedIn(false);
            const usernameErrorCodes = [
              'auth/operation-not-allowed',
              'auth/user-not-found',
              'auth/user-disabled'
            ];

            const emailErrorCodes = [
              'auth/email-already-in-use',
              'auth/invalid-email'
            ];

            const passwordErrorCodes = [
              'auth/weak-password',
              'auth/wrong-password'
            ];

            const response = {
              email: emailErrorCodes.includes(error.code)
                ? error.message
                : null,
              displayName: usernameErrorCodes.includes(error.code)
                ? error.message
                : null,
              password: passwordErrorCodes.includes(error.code)
                ? error.message
                : null
            };

            if (error.code === 'auth/invalid-api-key') {
              console.log('api key error');
            }
            if (onError) onError(error);
            reject(error);
          });
      }
    });
  };

  /**
   * For 3rd-party Authentication [e.g. Autho0, firebase, AWS etc]
   *
   */
  // const tokenAuth = (token, user = {}) => {
  //   setUser(user);
  //   setToken(token);
  //   addItem('token', token);
  //   setLoggedIn(true);
  // };

  // const forgetPass = params => {
  //   console.log(params, 'forget password form Props');
  // };
  // const changePass = params => {
  //   console.log(params, 'change password form Props');
  // };

  const logOut = async () => {
    saveState(`${encodeURIComponent(user.email)}_vessels`, user.vessels);
    await firebaseService.signOut();
    setLoggedIn(false);
    setEmailVerified(false);
    setUser(null);
    setSelectBoaterVessel(null);
    let remaining = sessionStorage.length;
    dispatch(reservationActions.clear());
  };

  // helpers
  const waitForUserLoggedIn = async () => {
    const promise = new Promise((resolve, reject) => {
      let counter = 0;
      const interval = setInterval(() => {
        if (counter > 20) {
          clearInterval(interval);
          reject();
        } else {
          if (firebaseService._logged_in) {
            clearInterval(interval);
            resolve(true);
          } else {
            counter++;
          }
        }
      }, 500);
    });
    return promise;
  };

  const waitForCurrentUserReload = async () => {
    const promise = new Promise((resolve, reject) => {
      let counter = 0;
      const interval = setInterval(() => {
        if (counter > 20) {
          clearInterval(interval);
          reject();
        } else {
          if (firebaseService.auth && firebaseService.auth.currentUser) {
            clearInterval(interval);
            resolve(true);
          } else {
            counter++;
          }
        }
      }, 300);
    });
    return promise;
  };

  const getStatisticsForBoater = async () => {
    try {
      if (waitingForStatistics) return;
      setWaitingForStatistics(true);

      const statistics = await boaterService.stats();
      setStatistics({ statistics });

      setWaitingForStatistics(false);
      return statistics;
    } catch (err) {
      setWaitingForStatistics(false);
      console.error(err);
    }
  };

  const selectBoaterVessel = id => {
    setSelectBoaterVessel(id);
    // save in locale storage
    saveState(`${encodeURIComponent(user.email)}_selected_vessel_id`, id);
  };

  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        logOut,
        signIn,
        signUp,
        signInFacebookAccount,
        signInGoogleAccount,
        refreshData,
        getStatisticsForBoater,
        waitingForAuth,
        updates,
        waitForUserLoggedIn,
        waitForCurrentUserReload,
        // forgetPass,
        // changePass,
        // tokenAuth,
        user,
        // token,
        selectedBoaterVessel,
        selectBoaterVessel,
        emailVerified,
        homeMarinaVerified,
        setHomeMarinaVerified
      }}
    >
      {/* {waitingForAuth ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin size="large" />
        </div>
      ) : ( */}
      <>{props.children}</>
      {/* )} */}
    </AuthContext.Provider>
  );
};

export default withRouter(AuthProvider);
